import React from 'react'
import {
  ActionButton,
  ActionButtonGroup,
  ExternalLinkIcon,
  Heading,
  Link
} from '@te-digi/styleguide'

const ActionButtonGroupExample = () => (
  <ActionButtonGroup
    heading={
      <Heading
        level={2}
        noMargin
        size={4}
      >
        Usein kysytyt kysymykset
      </Heading>
    }
  >
    <ActionButton
      href={'#'}
      as={Link}
      description="as Link component"
    >
      Link to this page
    </ActionButton>
    <ActionButton
      as="a"
      href={'http://www.google.com'}
      iconRight={<ExternalLinkIcon />}
      description="as native html &lt;a&gt; element"
      target="_blank"
    >
      Link to Google
    </ActionButton>
  </ActionButtonGroup>
)

export { ActionButtonGroupExample }
