import React from 'react'
import {
  ActionButton,
  ActionButtonGroup,
  Heading,
  Link
} from '@te-digi/styleguide'

const ActionButtonGroupExample2 = () => (
  <ActionButtonGroup
    heading={
      <Heading
        level={2}
        noMargin
        size={4}
      >
        Usein kysytyt kysymykset
      </Heading>
    }
  >
    {[
      <ActionButton
        href={'#'}
        as={Link}
        description="as Link component"
      >
        Link to this page
      </ActionButton>
    ]}
  </ActionButtonGroup>
)

export { ActionButtonGroupExample2 }
