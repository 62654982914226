import React from 'react'

import { LinkMock } from '../../components/LinkMock'

import lorem from '../../lorem'

import IconHTMLExample from './IconHTMLExample'

const ActionButtonGroupHTMLExample = () => (
  <div className="action-button-group">
    <div className="action-button-group_header">
      <h2 className="h4 mb-0">Usein kysytyt kysymykset</h2>
    </div>
    <ul className="action-button-group_list">
      <div className="action-button-group_list-item">
        <LinkMock className="action-button action-button--small">
          <span className="action-button-label action-button-label--small">
            <span className="action-button-label-heading action-button-label-heading--small">
              Työttömyysturva
            </span>
            <span className="action-button-label-description action-button-label-description--small">
              {lorem({ words: 4 })}
            </span>
          </span>
          <IconHTMLExample
            name="ArrowRightIcon"
            className="action-button-icon-right"
            size="lg"
          />
        </LinkMock>
      </div>
      <div className="action-button-group_list-item">
        <LinkMock className="action-button action-button--small">
          <span className="action-button-label action-button-label--small">
            <span className="action-button-label-heading action-button-label-heading--small">
              Työllistysmissuunnitelma
            </span>
            <span className="action-button-label-description action-button-label-description--small">
              {lorem({ words: 4 })}
            </span>
          </span>
          <IconHTMLExample
            name="ArrowRightIcon"
            className="action-button-icon-right"
            size="lg"
          />
        </LinkMock>
      </div>
      <div className="action-button-group_list-item">
        <LinkMock className="action-button action-button--small">
          <span className="action-button-label action-button-label--small">
            <span className="action-button-label-heading action-button-label-heading--small">
              Palkkatuki
            </span>
            <span className="action-button-label-description action-button-label-description--small">
              {lorem({ words: 4 })}
            </span>
          </span>
          <IconHTMLExample
            name="ArrowRightIcon"
            className="action-button-icon-right"
            size="lg"
          />
        </LinkMock>
      </div>
      <div className="action-button-group_list-item">
        <LinkMock className="action-button action-button--small">
          <span className="action-button-label action-button-label--small">
            <span className="action-button-label-heading action-button-label-heading--small">
              Työvoimakoulutukset ja valmennukset
            </span>
            <span className="action-button-label-description action-button-label-description--small">
              {lorem({ words: 4 })}
            </span>
          </span>
          <IconHTMLExample
            name="ArrowRightIcon"
            className="action-button-icon-right"
            size="lg"
          />
        </LinkMock>
      </div>
    </ul>
  </div>
)

export { ActionButtonGroupHTMLExample }
