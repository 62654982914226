import {
  ActionButtonGroup,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'
import React from 'react'

import { Content } from '../../components/Content'
import { Playground } from '../../components/Playground'
import { Section } from '../../components/Section'
import { ActionButtonGroupHTMLExample } from '../../examples/html/ActionButtonGroupHTMLExample'
import { ActionButtonGroupExample } from '../../examples/react/ActionButtonGroupExample'
import { ActionButtonGroupExample2 } from '../../examples/react/ActionButtonGroupExample2'
import { Code } from '../../components/Code'

const Page = () => (
  <Content
    heading="ActionButtonGroup"
    components={[{ component: ActionButtonGroup, restElement: true }]}
    status={[{ type: 'accessible', version: '15.1.0' }]}
  >
    <Section>
      <Playground example={ActionButtonGroupExample} />
      <Playground example={ActionButtonGroupExample2} />
      <Playground
        format="html"
        example={ActionButtonGroupHTMLExample}
      />
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>
          Huomioi, että <Code>ActionButtonGroup</Code> koostuu{' '}
          <Code>ActionButton</Code>-komponenteista, jotka ovat semanttisesti
          linkkejä eikä painikkeita.
        </ListItem>
        <ListItem>
          Varmista, että linkit on listattu ja listasta käy ilmi, kuinka monta
          linkkiä siinä on.
        </ListItem>
        <ListItem>
          Varmista, että painikkeen tekstistä käy ilmi, mihin käyttäjä siirtyy.
        </ListItem>
      </List>
    </Section>
  </Content>
)

export default Page
